// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/mobileHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/mobileHeader.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { Link, useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { AnimatePresence, motion } from "framer-motion";
import MobileNavLink from "../mobileNavLink/mobileNavLink";
import { fadeIn } from "~/lib/variant";
const MobileHeader = ({
  user
}) => {
  _s();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    setOpenMenu(false);
  }, [location.pathname]);
  return <header className={`flex w-full items-center flex-col md:hidden px-4 py-5
  ${openMenu ? "h-96" : "h-20 delay-500"} ${openMenu && "divide-y divide-foreground/60"} transition-all duration-300 bg-background/80 backdrop-blur fixed z-20`}>
      <div className="flex justify-between items-center w-full my-2 h-8 ">
        <Link to="/" className="h-12 2xl:h-7 select-none">
          <img src="/assets/logo.png" alt="XRayGlobal logo" className="h-full w-auto" />
        </Link>
        <Button size="icon" variant="ghost" onClick={() => setOpenMenu(!openMenu)} className="flex flex-col justify-center items-center group">
          <div className={`h-0.5 w-7 my-1 rounded-full bg-white transition ease transform duration-300 ${openMenu ? "rotate-45 translate-y-2.5 opacity-100 group-hover:opacity-100" : "opacity-100 group-hover:opacity-100 delay-500"}`} />
          <div className={`h-0.5 w-7 my-1 rounded-full bg-white transition ease transform duration-300 ${openMenu ? "opacity-0" : "opacity-100 group-hover:opacity-100 delay-500"}`} />
          <div className={`h-0.5 my-1 rounded-full bg-white transition-all ease transform duration-300 ${openMenu ? "-rotate-45 w-7 -translate-y-2.5 opacity-100 group-hover:opacity-100" : "opacity-100 w-4 group-hover:opacity-100 delay-500"}`} />
        </Button>
      </div>
      <AnimatePresence>
        {openMenu && <>
            <nav className="list-none pt-2 w-full flex flex-col gap-y-3">
              <MobileNavLink href="/">خانه</MobileNavLink>
              <MobileNavLink href="/about">درباره ما</MobileNavLink>
              <MobileNavLink href="/support">پشتیبانی</MobileNavLink>
              <MobileNavLink href="/blog">مقالات</MobileNavLink>
            </nav>
            <div className="list-none flex gap-x-2 w-full justify-center items-center pt-2 mt-2">
              {user ? <motion.li variants={fadeIn("left", 0.3, 0.5)} initial="hidden" animate="show" exit="hidden">
                  <Link to={user.role === "representer" ? "/representer" : "dashboard"}>
                    <Button className="px-5 py-3 text-sm 2xl:text-lg font-medium xl:px-6 xl:p-4">
                      پنل {user.role === "representer" ? "نمایندگی" : "کاربری"}
                    </Button>
                  </Link>
                </motion.li> : <>
                  <motion.li variants={fadeIn("left", 0.3, 0.5)} initial="hidden" animate="show" exit="hidden">
                    <Link title="ثبت نام" to="/signup">
                      <Button variant="default" className="text-white">
                        ثبت نام
                      </Button>
                    </Link>
                  </motion.li>
                  <motion.li variants={fadeIn("right", 0.3, 0.5)} initial="hidden" animate="show" exit="hidden">
                    <Link title="ورود" to="/login">
                      <Button variant="outline">ورود</Button>
                    </Link>
                  </motion.li>
                </>}
            </div>
          </>}
      </AnimatePresence>
    </header>;
};
_s(MobileHeader, "fHicCtHVYdBmEjwIw96YpO5W81o=", false, function () {
  return [useLocation];
});
_c = MobileHeader;
export default MobileHeader;
var _c;
$RefreshReg$(_c, "MobileHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;