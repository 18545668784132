// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/header.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { Link, NavLink } from "@remix-run/react";
import { Button } from "../ui/button";
import { Loader2 } from "lucide-react";
const Header = ({
  user
}) => {
  return <header className="hidden md:flex justify-center">
      <div className="fixed flex gap-x-12 justify-between md:px-12 lg:px-16 xl:px-20 2xl:px-24 items-center z-20
        lg:max-h-20 2xl:max-h-28 bg-background/80 w-full backdrop-blur gap-2 py-5 mx-auto">
        <Link to="/" className="h-12 2xl:h-16 w-56">
          <img src="/assets/logo.png" alt="XRayGlobal logo" className="h-[120%] w-auto animate-pulse" />
        </Link>
        <nav className="flex items-center gap-x-6">
          <NavLink to="/">
            {({
            isActive,
            isPending
          }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                خانه
              </Button>}
          </NavLink>
          <NavLink to="/about">
            {({
            isActive,
            isPending
          }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                درباره ما
              </Button>}
          </NavLink>
          <NavLink to="/blog">
            {({
            isActive,
            isPending
          }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                مقالات
              </Button>}
          </NavLink>
          <NavLink to="/support">
            {({
            isActive,
            isPending
          }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                پشتیبانی
              </Button>}
          </NavLink>
          <NavLink to="/privacy">
            {({
            isActive,
            isPending
          }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                حریم خصوصی
              </Button>}
          </NavLink>
        </nav>
        <div className="flex items-center gap-x-2 w-56 justify-end">
          {user ? <Link to={user.role === "representer" ? "/representer" : "dashboard"}>
              <Button className="px-5 py-3 text-sm 2xl:text-lg font-medium xl:px-6 xl:p-4">
                پنل {user.role === "representer" ? "نمایندگی" : "کاربری"}
              </Button>
            </Link> : <>
              <Link to="/signup">
                <Button className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                  ثبت نام
                </Button>
              </Link>
              <Link to="/login">
                <Button variant="secondary" className="text-sm font-medium xl:text-base 2xl:text-lg px-5 py-3 2xl:px-6 2xl:py-3.5">
                  ورود
                </Button>
              </Link>
            </>}
        </div>
      </div>
    </header>;
};
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;