// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/mobileNavLink/mobileNavLink.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/mobileNavLink/mobileNavLink.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { NavLink } from "@remix-run/react";
import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import { fadeIn } from "~/lib/variant";
import { Button } from "../ui/button";
const MobileNavLink = ({
  children,
  href
}) => {
  return <motion.li variants={fadeIn("right", 0.3, 0.2)} initial="hidden" animate="show" exit="hidden" className="w-full flex items-center justify-center">
      <NavLink title={children?.toString()} to={href} className="w-full">
        {({
        isActive,
        isPending
      }) => <Button variant={isActive ? "outline" : "ghost"} size="lg" className="font-medium justify-start w-full">
            {isPending && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
            {children}
          </Button>}
      </NavLink>
    </motion.li>;
};
_c = MobileNavLink;
export default MobileNavLink;
var _c;
$RefreshReg$(_c, "MobileNavLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;