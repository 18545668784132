// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/index.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import Header from "./header";
import MobileHeader from "./mobileHeader";
import Footer from "./footer";
import { useLocation } from "@remix-run/react";
import ParticlesContainer from "../particlesContainer/particlesContainer";
const Layout = ({
  children,
  user
}) => {
  _s();
  const location = useLocation();
  return <>
      <ParticlesContainer />
      {location.pathname.includes("dashboard") || location.pathname.includes("representer") ? "" : <>
          <Header user={user} />
          <MobileHeader user={user} />
        </>}
      <main className="w-full flex flex-col pt-24 min-h-screen">
        {children}
      </main>
      {location.pathname.includes("dashboard") || location.pathname.includes("representer") ? "" : <Footer />}
    </>;
};
_s(Layout, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c = Layout;
export default Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;