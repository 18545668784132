// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/particlesContainer/particlesContainer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/particlesContainer/particlesContainer.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { tsParticles } from "@tsparticles/engine";
import { loadPolygonMaskPlugin } from "@tsparticles/plugin-polygon-mask";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadFull } from "tsparticles";
const ParticlesContainer = () => {
  _s();
  const [init, setInit] = useState(false);
  const options = useMemo(() => ({
    fullScreen: {
      enable: false
    },
    background: {
      color: {
        value: ""
      }
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: false,
          mode: "push"
        },
        onHover: {
          enable: true,
          mode: "repulse"
        },
        resize: true
      },
      modes: {
        push: {
          quantity: 90
        },
        repulse: {
          distance: 100,
          duration: 0.4
        }
      }
    },
    particles: {
      color: {
        value: "#fafafa"
      },
      links: {
        color: "#f1f1f1",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1
      },
      collisions: {
        enable: true
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce"
        },
        random: false,
        speed: 1,
        straight: false
      },
      number: {
        density: {
          enable: true,
          area: 800
        },
        value: 80
      },
      opacity: {
        value: 0.5
      },
      shape: {
        type: "circle"
      },
      size: {
        value: {
          min: 1,
          max: 5
        }
      }
    },
    detectRetina: true
  }), []);
  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadPolygonMaskPlugin(tsParticles);
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  const particlesLoaded = useCallback(async container => {}, []);
  return <div className="w-screen h-screen fixed -z-20">
      {init && <Particles className="w-screen h-screen fixed -z-20" id="tsparticles"
    // @ts-ignore
    options={options} particlesLoaded={particlesLoaded} />}
    </div>;
};
_s(ParticlesContainer, "sy/Wskmonaobj3UuUtrLkjdUk5s=");
_c = ParticlesContainer;
export default ParticlesContainer;
var _c;
$RefreshReg$(_c, "ParticlesContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;