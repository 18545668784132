// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from "@remix-run/css-bundle";
import styles from "./globals.css";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, json, useLoaderData, useNavigation } from "@remix-run/react";
import Layout from "./components/layout";
import { Toaster } from "./components/ui/toaster";
import authenticator from "./services/auth.server";
import Typography from "./components/typography/typography";
import { Loader2 } from "lucide-react";
import GoftinoWidget from "./components/goftino/goftino";
import driverStyle from "driver.js/dist/driver.css";
export const links = () => [{
  rel: "stylesheet",
  href: driverStyle
}, {
  rel: "stylesheet",
  href: styles
}, ...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : [])];
export const loader = async ({
  request
}) => {
  const user = await authenticator.isAuthenticated(request);
  return json({
    user
  });
};
export default function App() {
  _s();
  const data = useLoaderData();
  const navigation = useNavigation();
  return <html lang="fa" dir="rtl" className="dark overflow-x-hidden">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script async src={`https://www.googletagmanager.com/gtag/js?id=GTM-N2F94MKV`} />

        <script dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'GTM-N2F94MKV');
            `
      }} />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />

        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />

        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

        <link rel="manifest" href="/site.webmanifest" />
      </head>
      <body className="overflow-x-hidden bg-background w-full">
        {navigation.state === "loading" && <div className="fixed z-[1000] inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="flex flex-col justify-center items-center px-6 py-4 bg-foreground/95 text-background rounded-2xl">
              <Typography variant="title1">درحال بارگیری</Typography>
              <Loader2 className="animate-spin mt-3 h-8 w-8" />
            </div>
          </div>}

        <Layout user={data.user}>
          <Outlet />
        </Layout>
        <GoftinoWidget />
        <Toaster />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(App, "kIyfHko9Qfy095I2sKlcMTHWjHQ=", false, function () {
  return [useLoaderData, useNavigation];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;