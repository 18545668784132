// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/goftino/goftino.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/goftino/goftino.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { GoftinoSnippet } from '@mohsen007/react-goftino';
function GoftinoWidget() {
  // useEffect(() => {
  //   const loadScript = () => {
  //     const script = document.createElement("script");
  //     const widgetId = "jyuasC";
  //     const scriptSrc = `https://www.goftino.com/widget/${widgetId}`;
  //     const localData = localStorage.getItem(`goftino_${widgetId}`);
  //     script.async = true;
  //     script.src = localData ? `${scriptSrc}?o=${localData}` : scriptSrc;
  //     document.getElementsByTagName("head")[0].appendChild(script);
  //   };

  //   if (document.readyState === "complete") {
  //     loadScript();
  //   } else {
  //     window.addEventListener("load", loadScript);
  //   }

  //   return () => {
  //     // Cleanup if needed
  //   };
  // }, []);

  return <GoftinoSnippet goftinoKey="jyuasC" />; // or any component you want to render
}
_c = GoftinoWidget;
export default GoftinoWidget;
var _c;
$RefreshReg$(_c, "GoftinoWidget");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;